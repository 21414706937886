.reporting-dashboard {
    padding: 20px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Highlight Cards Section */
.highlight-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);  /* Create 4 equal-width columns */
    gap: 20px;
    justify-content: space-between;
}

.highlight-card {
    padding: 20px;
    text-align: left;  /* Align text to the left for the metric and percentage */
    background-color: #f8f9fa;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-metric {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.main-value {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.percentage-change {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
}

.percentage-change.increase {
    background-color: rgba(0, 170, 255, 0.1);
    color: #00aaff;
}

.percentage-change.decrease {
    background-color: rgba(255, 165, 0, 0.1);
    color: #ffa500;
}

.arrow {
    margin-right: 5px;
}

.small-text {
    font-size: 14px;
    color: #666;
}

/* Graphs Section */
.graphs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 20px;
}

.filter-boxes {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.filter-boxes button {
    padding: 10px 20px;
    border: none;
    background-color: #00aaff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}

.filter-boxes button:hover {
    background-color: #0077cc;
}

.bar-graph-container, .line-graph-container, .crm-graph-container {
    max-width: 100%;
    height: 500px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.bar-card, .line-card, .crm-card {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 8px;
    background: white;
    padding: 20px;
    position: relative;
}

.bar-card {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.crm-card {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.line-card {
    grid-column: 1 / 3;  /* Span across both columns */
    grid-row: 2 / 3;
    width: 100%;
}

.card-title {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.card-content {
    padding-top: 40px;
}
