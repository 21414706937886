/* Custom CSS for month transition animations */
.slide-out-right {
  animation: slideOutRight 0.3s forwards;
}

.slide-in-left {
  animation: slideInLeft 0.3s forwards;
}

.slide-out-left {
  animation: slideOutLeft 0.3s forwards;
}

.slide-in-right {
  animation: slideInRight 0.3s forwards;
}

@keyframes slideOutRight {
  from { transform: translateX(0); opacity: 1; }
  to { transform: translateX(100%); opacity: 0; }
}

@keyframes slideInLeft {
  from { transform: translateX(-100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slideOutLeft {
  from { transform: translateX(0); opacity: 1; }
  to { transform: translateX(-100%); opacity: 0; }
}

@keyframes slideInRight {
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}
