.user-uploads-container {
  padding: 20px;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.user-uploads-grid {
  height: 100%;
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.centered-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}
