.queue-mapping-management-container {
  margin: 20px;
  padding-top: 0;
}

.queue-mapping-management-grid {
  height: 80vh;
  width: 100%;
  margin-top: 0;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
