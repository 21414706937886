@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
@import '~antd/dist/antd.css';

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%; 
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  top: 0;
}

.scrollable-container {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  position: fixed; 
  top: 70px;
  right: 0;
  padding-bottom: 40px;
  margin: 0;
}

.scrollable-container::-webkit-scrollbar {
  width: 8px; 
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

.overlay {
  position: relative;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  overflow: hidden;
}

.form-field .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: blue;
}

.form-field .MuiOutlinedInput-root {
  height: 40px;
}

.form-field .MuiInputBase-input {
  text-align: center;
  font-size: 0.875rem;
}

.MuiGrid-item {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

/* New login page specific styles */
.login-page {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.login-page .scrollable-container {
  top: 0;
  position: relative;
  height: 100vh;
}

.login-page .main-content {
  padding: 0;
  height: 100vh;
}

.login-page #root {
  overflow: auto;
}

/* Original media queries */
@media (max-width: 768px) {
  .main-content {
    padding-top: 56px;
  }
}

@media (max-width: 600px) {
  .main-content {
    padding: 8px;
  }
  
  .overlay {
    padding: 8px;
  }
}
