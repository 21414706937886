.feedback-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.15);
  padding: 24px;
  width: 500px;
  max-width: 90%;
  border-radius: 10px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: whitesmoke;
}

.feedback-modal .modal-header,
.feedback-modal .modal-form {
  color: whitesmoke;
}

.feedback-modal .modal-form .MuiInputBase-root,
.feedback-modal .modal-form .MuiInputLabel-root,
.feedback-modal .modal-form .MuiSelect-root,
.feedback-modal .modal-form .MuiSelect-icon {
  color: whitesmoke;
}

.feedback-modal .modal-form .MuiOutlinedInput-root fieldset {
  border-color: whitesmoke;
}

.feedback-modal .modal-form .MuiOutlinedInput-root:hover fieldset,
.feedback-modal .modal-form .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: whitesmoke;
}

.feedback-modal .modal-form .MuiInputLabel-root.Mui-focused {
  color: whitesmoke;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-right: 8px; /* Add padding to ensure the button has some spacing from the edge */
  position: relative;
}

.modal-header .MuiIconButton-root {
  position: absolute;
  top: 0;
  right: 0;
}
