.users-list-wrapper {
  width: 100%;
  padding: 16px;
}

.users-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  align-items: start;
  align-content: start;
  grid-auto-rows: min-content
}

@media (max-width: 768px) {
  .users-list {
    grid-template-columns: 1fr;
  }
}
