.account-management-container {
	margin: 20px;
	padding-bottom: 40px;
}

.account-management-grid {
	height: 75vh;
	width: 100%;
	margin-top: 0;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
