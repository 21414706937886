html,
body {
	background: #BDBDBD;
}

.main-content {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.overlay {
	padding: 8px;
	max-width: 100%;
}

.MuiGrid-item {
	padding-top: 4px !important;
	padding-bottom: 4px !important;
}
